import styled from "styled-components";

export const PageContainer = styled.div`
    padding-top: 90px;
    // text-align: center;
    margin: 0 auto;
`

export const PageTitle = styled.h1`
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
`

export const MainContainer = styled.main`
    min-height: 100vh;
`